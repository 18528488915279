<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
                <CCol col="6" class="text-left"><h4>New Merchant</h4></CCol>
                <CCol col="6" class="text-right"> 
            </CCol> 
            </CRow>     
          </CCardHeader>
          <CCardBody>               
            <CForm>
              <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
              </CRow>

                  
           <b-tabs content-class="mt-3">
              <b-tab title="General">

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small" >Wallet Group</label>
                  </b-col>   
                  <b-col sm="10"> 
                     <b-form-select v-model="WGNumber" :options="optWGList"></b-form-select>
                   </b-col>                  
                </b-row><br/>                 

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small" >Display Name</label>
                  </b-col>   
                  <b-col sm="4">                     
                      <CInput :disabled="!isDisableddisName" placeholder="Type here..." v-model="displayName"/>
                  </b-col>
                  <b-col sm="2">                    
                     <label for="input-small">User Name</label>
                  </b-col>   
                  <b-col sm="4"> 
                      <CInput :disabled="!isDisableduName" placeholder="Type here..." v-model="userName" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small" >First Name</label>
                  </b-col>   
                  <b-col sm="4">                     
                      <CInput :disabled="!isDisabledfName" placeholder="Type here..." v-model="firstName"/>
                  </b-col>
                  <b-col sm="2">                    
                     <label for="input-small">Last Name </label>
                  </b-col>   
                  <b-col sm="4"> 
                      <CInput :disabled="!isDisabledlName" placeholder="Type here..." v-model="lastName" /> 
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Branch Name</label>
                  </b-col>   
                  <b-col sm="10">                     
                      <CInput :disabled="!isDisabledbrN" placeholder="Type Branch Name"  v-model="branchName" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Email Address</label>
                  </b-col>   
                  <b-col sm="10">                     
                      <CInput :disabled="!isDisabledeml" placeholder="Type Email Address" v-model="emailAddress" />
                  </b-col>
                </b-row>

                <!-- <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Mobile Number</label>
                  </b-col>   
                  <b-col sm="3"> 
                      <b-form-select :disabled="!isDisabledcCode" v-model="countryCode" :options="optcountryCode"></b-form-select>
                  </b-col>
                   <b-col sm="7">                     
                      <CInput :disabled="!isDisabledmnum" v-model="mobileNumber" />
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col sm="2">                    
                     <label for="input-small">Address</label>
                  </b-col>   
                  <b-col sm="10">
                      <CTextarea :disabled="!isDisabledaddrss" v-model="address" placeholder="Type Address" rows="3"/>
                  </b-col>
                </b-row>

                <CRow>
                <CCol col="6" class="text-left"></CCol>
                <CCol col="6" class="text-right">                  
                    <b-button-group> 
                       <router-link to="/merchant" tag="b-button">Cancel</router-link>
                       <b-button variant="primary" v-on:click="actionSubmit()">Submit</b-button>
                    </b-button-group>
                </CCol> 
                </CRow>
              </b-tab>
              <!-- <b-tab title="Resources" >
                 <resources />
              </b-tab>  -->
              <!-- <b-tab title="Wallet" >
                <wallet />
              </b-tab>                              -->
           </b-tabs> 
            
           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js'; 
 import MerchantService from '@/api/MerchantService.js'; 
 
  export default { 
    name: 'new_merchant',
    components: { 
    },     
    data() { var date = new Date();
      return {          
          recordId:'',
          merchantId:'',
          displayName:'', 
          userName:'', 
          firstName:'',
          lastName:'',
          branchName:'',
          emailAddress:'',
          countryCode:'',
          optcountryCode:[],
          mobileNumber:'',
          address:'',
          merchantStatus:'',
          optStatus:[],
          WGNumber:'',
          optWGList:[],
          isDisabledeml:true,
          isDisabledmchId:true,
          isDisableddisName:true,  
          isDisableduName:true,
          isDisabledcCode:true, 
          isDisabledmnum:true, 
          isDisabledfName:true,
          isDisabledlName:true, 
          isDisabledaddrss:true, 
          isDisabledsts:true, 
          isDisabledbrN:true, 
          seenBtnCcel: false,
          seenBtnWarn: true,
          seenBtnUpdate:false,          
          seen:false,
          msg :'',
          color:''
        };      
    },
    created () {      
        this.getWalletGroupList();
    },  
    methods: { 
        
      resetForm(){
         this.WGNumber = '';
         this.displayName = '';
         this.userName = '';
         this.firstName = '';
         this.lastName = '';
         this.branchName = '';
         this.emailAddress = '';
         this.address = '';
      },

      actionSubmit(){         

        let params = {}; 
        //params['acquirerRefId'] = "";     
        params['address'] = this.address;
        params['branchName'] = this.branchName;
        params['displayName'] = this.displayName;
        params['emailAddress'] = this.emailAddress;  
        params['firstName'] = this.firstName;
        params['lastName'] = this.lastName;
        params['userName'] = this.userName; 
       
        var validate = [];
        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }
       
        if(validate.includes(false) || this.WGNumber === ''){
            this.msg   = "Not complete data, All fields required !"; 
            this.color = 'warning'; this.seen  = true;  
            setTimeout( () =>  this.seen=false , 5000);
        } else { //console.log(params); console.log(this.WGNumber);    
            this.createNewMerchant(this.WGNumber,params);           
        }

      },  

      createNewMerchant: function(num,params) {
        MerchantService.createNewMerchant(num,params).then(resp => { //console.log(resp);
            if(resp.message==="Success"){                  
              this.msg   = "Create new Merchant Success!"; 
              this.color = 'success'; this.seen  = true;  this.resetForm();            
            } else {
              this.msg   =  !resp.data.message ? "Create New Merchant Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }
            setTimeout( () => this.seen=false , 5000); 
        }, error => {
          this.loading = false;
        });
      }, 

      getWalletGroupList: function() {
        AuthService.getWalletGroupList().then(resp => { //console.log(resp);

            var WGList = resp; var WGListSrc=[];
              for (var key in WGList) {
                  var GrpUNum = WGList[key].groupUniqueNumber; 
                  var GrpName = WGList[key].name;  
                  WGListSrc.push({ value: GrpUNum , text: GrpName });
              }
            this.optWGList = WGListSrc;

        }, error => {
          this.spinner = false;
        });
      }

    }
  }
</script>